// const AMEX_API_URL = 'https://172.20.19.10:9333/';
// const AMEX_TOKEN_URL = 'https://172.20.19.10:9333/';

const MYBOAT_API_URL = 'https://boat-api.veroke.com/api/cms/'

const MYBOAT_IMG_URL = 'https://boat-api.veroke.com/uploads/'
export default {
  MYBOAT_API_URL,
  MYBOAT_IMG_URL,
}
